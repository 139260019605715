import { Link, useLocation, useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import { ShoppingCart, User } from "react-feather"
import RtButton from "components/button/RtButton"
import UserProfileModal from "components/modal/UserProfileModal"
import { useState } from "react"
import {
  canHostEvent,
  canViewMyEvents,
  canViewMyTickets,
  isAdmin,
  isAuthenticated,
} from "utils/user.util"
import { useSelector } from "react-redux"
import { getCartCountLockId } from "./util"
import { RTNavLink } from "./RTNavLink"
import { UN_AUTHENTICATED_ROUTES } from "constants/global.constants"
import { showToaster } from "utils/show-toaster.util"
import { CartInterface } from "redux/features/cart"

function TopNav() {
  const navigate = useNavigate()
  const location = useLocation()
  const cart = useSelector((state: any) => state.cart) as CartInterface

  const { checkOutUrl, cartCount } = getCartCountLockId(cart)

  const [isUserModalOpen, setIsUserModalOpen] = useState(false)

  const toggleUserModal = () => setIsUserModalOpen(!isUserModalOpen)

  const navigateToHostEvent = () => navigate("/host-event")

  const navLinks = [
    {
      display: "Home",
      to: isAdmin() ? "/?page=1" : "/",
      matchWith: "/",
      isUserAllowed: true,
    },
    {
      display: "My Tickets",
      to: "/my-tickets/?tab=Current",
      matchWith: "my-tickets",
      isUserAllowed: canViewMyTickets(),
    },
    {
      display: "Events",
      to: "/events/?page=1",
      matchWith: "events",
      isUserAllowed: isAdmin(),
    },
    {
      display: "Sales",
      to: "/sales",
      matchWith: "sales",
      isUserAllowed: isAdmin(),
    },
    {
      display: "Withdrawals",
      to: "/withdrawal-requests/?page=1",
      matchWith: "withdrawal-requests",
      isUserAllowed: isAdmin(),
    },
    {
      display: "My Events",
      to: "/my-events",
      matchWith: "my-events",
      isUserAllowed: canViewMyEvents(),
    },
  ].filter((navLink) => navLink.isUserAllowed)

  const isUnauthenticatedRoute = UN_AUTHENTICATED_ROUTES.some((router) =>
    location.pathname.includes(router),
  )

  const handleCart = () => {
    if (cartCount > 0 && checkOutUrl) {
      navigate(checkOutUrl)
    } else {
      showToaster("error", "Cart is empty")
    }
  }

  return (
    <nav className="bg-rt-white shadow-sm shadow-rt-blue">
      <div className="flex flex-row justify-between items-center py-2 px-2  lg:px-10">
        <Link to="/">
          <img
            src={logo}
            className="w-[200px] lg:w-[400px] h-14 object-cover"
            alt="Rapid Tech Insights Logo"
          />
        </Link>
        {canHostEvent() && (
          <RtButton onClick={navigateToHostEvent}>Host Event</RtButton>
        )}
        {!isUnauthenticatedRoute && !isAuthenticated() && (
          <div className="flex gap-4 justify-end w-full">
            <RtButton variant="solid" onClick={() => navigate("/login")}>
              Log in
            </RtButton>
            <RtButton variant="outline" onClick={() => navigate("/sign-up")}>
              Create Account
            </RtButton>
          </div>
        )}
      </div>

      {isAuthenticated() && (
        <div className="py-4 tablet:px-10 px-2 flex flex-row justify-between items-center  border-t border-rt-light-white-smoke	">
          <>
            <div className="flex  gap-x-3 font-poppins">
              {navLinks.map((navLink, index) => (
                <RTNavLink
                  key={index}
                  to={navLink.to}
                  display={navLink.display}
                  matchWith={navLink.matchWith}
                />
              ))}
            </div>

            <div className="flex gap-4">
              {!isAdmin() && (
                <div className="relative">
                  <div className="absolute top-[-15px] left-[-15px] bg-rt-violet text-rt-white text-[10px] font-bold  rounded-full px-2 py-1">
                    {cartCount}
                  </div>

                  <button onClick={handleCart}>
                    <ShoppingCart
                      className=" text-rt-blue"
                      size={25}
                      strokeWidth={3}
                    />
                  </button>
                </div>
              )}
              <User
                className="text-rt-blue"
                size={25}
                strokeWidth={3}
                onClick={toggleUserModal}
              />
              <UserProfileModal
                isOpen={isUserModalOpen}
                toggleModal={toggleUserModal}
              />
            </div>
          </>
        </div>
      )}
    </nav>
  )
}

export default TopNav
