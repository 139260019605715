import { RtInputProps } from "types/component-props.types"
import RTError from "./RTError"
import RTFormHelperText from "./RTFormHelperText"
import { useState } from "react"

function RtInput({
  label,
  inputName,
  inputType = "text",
  inputPlaceholder,
  inputOverrideClass,
  labelOverrideClass,
  isRequired,
  onInputChange,
  icon,
  endIcon,
  onKeyUp,
  inputValue,
  inputContainerOverrideClass,
  errorFieldName,
  formHelperText,
  isDisabled,
}: RtInputProps) {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className={`flex-1 ${inputContainerOverrideClass}`}>
      {label && (
        <label
          className={`block capitalize text-left text-sm text-rt-gray mb-1 ${labelOverrideClass}`}
        >
          {label}{" "}
          {isRequired ? (
            <span className="text-rt-red">*</span>
          ) : (
            <span className="text-rt-gray"> (optional)</span>
          )}
        </label>
      )}

      {formHelperText && <RTFormHelperText helperText={formHelperText} />}

      <div
        className={`container relative flex items-center justify-center  border-2 ${
          isFocused
            ? "border-rt-light-blue"
            : "border-rt-gray border-opacity-20"
        }  rounded-lg bg-rt-white `}
      >
        {icon && <div className="ml-2">{icon}</div>}
        <input
          className={`border-none outline-none rounded-lg w-full pl-2  bg-opacity-10 text-rt-gray-300 leading-tight focus:ring-0 placeholder-gray-450 ${
            icon ? "pl-10" : ""
          }placeholder:capitalize placeholder:text-sm ${inputOverrideClass}`}
          type={inputType}
          name={inputName}
          disabled={Boolean(isDisabled)}
          {...(inputValue && { value: inputValue })}
          onChange={(e) =>
            onInputChange && onInputChange({ [inputName]: e.target.value })
          }
          onKeyUp={onKeyUp}
          placeholder={inputPlaceholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {endIcon && <div className="mr-2">{endIcon}</div>}
      </div>

      <RTError inputName={errorFieldName || inputName} />
    </div>
  )
}

export default RtInput
