import RtTitle from "components/title/RtTitle"
import { ChevronLeft } from "react-feather"
import { useNavigate } from "react-router-dom"
import { PageNavProps } from "types/component-props.types"
import { twMerge } from "tailwind-merge"

function PageNav({
  children,
  backLink,
  onBack,
  overrideWrapper,
}: PageNavProps) {
  const navigator = useNavigate()

  const handleNavigateBack = () => {
    if (onBack) {
      onBack()
      return
    }

    if (backLink) {
      navigator(backLink)
      return
    }

    navigator(-1)
  }
  return (
    <div
      className={twMerge(
        `py-4 flex items-center justify-between ${overrideWrapper}`,
      )}
    >
      <span>
        <ChevronLeft
          className="text-rt-blue"
          size={30}
          strokeWidth={1}
          onClick={() => handleNavigateBack()}
        />
      </span>
      <RtTitle overrideClass="text-center font-bold flex-1 text-black">
        {children}
      </RtTitle>
    </div>
  )
}

export default PageNav
