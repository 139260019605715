import { formatStartEndDateToIso } from "utils/date-formatter.util"

function formatHostEventFormData(payload: any) {
  const formData = new FormData()

  const { start_date, end_date } = formatStartEndDateToIso(
    payload.start_date,
    payload.end_date,
  )

  formData.append("name", payload.name)
  formData.append("start_date", start_date)
  formData.append("end_date", end_date)
  formData.append("description", payload.description)
  formData.append("images[]", payload.image)

  if (payload.category) {
    formData.append("category_id", payload.category.id)
  }

  if (payload.place) {
    formData.append("place_id", payload.place.place_id)
  }

  if (payload.ticket_groups) {
    payload.ticket_groups.forEach((ticketGroup: any, idx: number) => {
      const { start_date, end_date } = formatStartEndDateToIso(
        ticketGroup.start_date,
        ticketGroup.end_date,
      )

      formData.append(`ticket_groups[${idx}][name]`, ticketGroup.name)
      formData.append(`ticket_groups[${idx}][price]`, ticketGroup.price)
      formData.append(`ticket_groups[${idx}][start_date]`, start_date)
      formData.append(`ticket_groups[${idx}][end_date]`, end_date)
      formData.append(`ticket_groups[${idx}][seats]`, ticketGroup.seats)
    })
  }

  return formData
}

export { formatHostEventFormData }
