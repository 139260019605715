import SectionContainer from "components/layout/SectionContainer"
import RtTitle from "components/title/RtTitle"

function Sales() {
  return (
    <SectionContainer>
      <RtTitle>Sales</RtTitle>

      <h2 className="text-center mt-8 font-semibold text-lg">Coming soon...</h2>
    </SectionContainer>
  )
}

export default Sales
