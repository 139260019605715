import { useQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"
import { queryKeyGenerator } from "utils/queryKeyGenerator"

export function useGetLoggedInUser(isEnabled = true) {
  return useQuery({
    queryKey: queryKeyGenerator.me(),
    enabled: isEnabled,
    queryFn: async () => await handleGetRequest(`/me`),
  })


}
