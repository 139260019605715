import PageNav from "components/nav/PageNav"
import EventInformation from "./event-information"
import TicketInformation from "./ticket-information"
// import PaymentMethod from "./payment-method"
// import PaymentInformation from "./payment-information"
import RtButton from "components/button/RtButton"
import { Zap } from "react-feather"
import SectionContainer from "components/layout/SectionContainer"
import Counter from "components/count/Counter"
import { useGetPreCheckoutData } from "hooks/api/useGetPreCheckoutData"
import Spinner from "components/loader/Spinner"
import { useNavigate, useParams } from "react-router-dom"
import { getLockRemainingSeconds } from "utils/get-lock-remaining-time.util"
import { useEffect, useState } from "react"
import BaseModal from "components/modal/BaseModal"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import { useProceedToPayment } from "hooks/api/useProceedToPayment"

function Checkout() {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const { slug, lockId, expiresAt } = useParams()

  const remTimeInSeconds = getLockRemainingSeconds(expiresAt!)

  const { data, isLoading, isError, refetch } = useGetPreCheckoutData(lockId)

  const handleNavigateHome = () => navigate("/")
  const handlePurchaseTickets = () => navigate(`/events/${slug}`)

  const queryClient = useProceedToPayment({
    lockId: lockId!,
    slug: slug!,
    isFree: data?.data.ticket_info.is_free,
  })

  const handleCheckout = async () => {
    queryClient.mutate()
  }

  useEffect(() => {
    if (isError) setShowModal(isError)
  }, [isError])

  if (isLoading) return <Spinner />

  return (
    <div className="bg-rt-light-violet min-h-screen">
      {data ? (
        <>
          <SectionContainer>
            <PageNav>Checkout</PageNav>
            <Counter
              timeInSeconds={remTimeInSeconds}
              cbWhenTimeExpires={refetch}
            />
          </SectionContainer>
          <EventInformation event={data.data.event} />
          <TicketInformation ticketInfo={data.data.ticket_info} slug={slug} />
          {/* {!data.data.ticket_info.is_free ? (
            <>
              <PaymentMethod />
              <PaymentInformation />
            </>
          ) : null} */}
          <SectionContainer overrideClass=" text-center">
            <RtButton
              icon={<Zap color="white" className="mr-2" />}
              overrideClass="w-full"
              onClick={handleCheckout}
            >
              {data.data.ticket_info.is_free
                ? "Proceed To Free Checkout"
                : "Proceed to payment"}
            </RtButton>
          </SectionContainer>
        </>
      ) : null}

      <ActionLoadingModal
        isOpen={queryClient.isPending}
        loadingText="Please wait as your checkout is being processed"
      />

      <BaseModal isOpen={showModal} onClose={() => {}}>
        <div className="text-center">
          <h2>
            Oops! your ticket expired, no need to worry you can secure the
            tickets again.
          </h2>
          <div className="flex items-center  justify-between">
            <RtButton overrideClass="mt-4" onClick={handlePurchaseTickets}>
              Buy Same Tickets
            </RtButton>

            <RtButton overrideClass="mt-4" onClick={handleNavigateHome}>
              View other Events
            </RtButton>
          </div>
        </div>
      </BaseModal>
    </div>
  )
}

export default Checkout
