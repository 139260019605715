import { useInfiniteQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"

interface ParamsInterface {
  q: string
  status: number
  is_active: 1 | 0
}

export function useAdminEvents(params: Partial<ParamsInterface>) {
  return useInfiniteQuery({
    queryKey: ["listAdminEvents", params],
    queryFn: async ({ pageParam }) =>
      await handleGetRequest("admin/events", { ...params, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.meta.current_page
      const lastPage_ = lastPage.data.meta.last_page

      const hasNextPage = currentPage < lastPage_

      return hasNextPage ? currentPage + 1 : undefined
    },
  })
}
