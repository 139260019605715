import SectionContainer from "components/layout/SectionContainer"
import MyTicketTabs from "./my-ticket-tabs"
import { useListMyTickets } from "hooks/api/useListMyTicket"
import Spinner from "components/loader/Spinner"
import RtButton from "components/button/RtButton"
import { useState } from "react"
import TicketQrCodeModal from "components/modal/TicketQrCodeModal"
import { EventInterFace, ITicketCodeSlug } from "types/index.types"
import TicketPreview from "components/ticket/TicketPreview"
import RtGrid from "components/grid/RtGrid"
import { AnimatePresence, motion } from "framer-motion"

export const MyTickets = () => {
  const { data, isLoading } = useListMyTickets()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [eventSlugTicketCode, setEventSlugTicketCode] =
    useState<ITicketCodeSlug>({
      event_slug: "",
      ticket_code: "",
    })

  const handleOpenTicketQrCodeModal = (eventTicketCode: ITicketCodeSlug) => {
    setIsModalOpen(!isModalOpen)

    setEventSlugTicketCode(eventTicketCode)
  }

  const tickets: Partial<EventInterFace>[] = data?.data?.data

  return (
    <div>
      <MyTicketTabs />
      <SectionContainer overrideClass="flex flex-col gap-y-2">
        {isLoading ? (
          <Spinner />
        ) : (
          <RtGrid>
            <AnimatePresence>
              {tickets.map((ticket, index: number) => (
                <motion.div
                  key={ticket.slug}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <TicketPreview
                    linkTo={`/events/${ticket.event_slug}`}
                    key={`ticket-${index}`}
                    event={ticket}
                    ticketType={ticket.ticket_group_name || ""}
                  >
                    <div className="text-right mt-2">
                      <RtButton
                        onClick={() =>
                          handleOpenTicketQrCodeModal({
                            event_slug: ticket.event_slug!,
                            ticket_code: ticket.ticket_code!,
                          })
                        }
                      >
                        SHOW QR CODE
                      </RtButton>
                    </div>
                  </TicketPreview>
                </motion.div>
              ))}
            </AnimatePresence>
          </RtGrid>
        )}
      </SectionContainer>
      <TicketQrCodeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        ticketSlugCode={eventSlugTicketCode}
      />
    </div>
  )
}
