import RtButton from "components/button/RtButton"
import Counter from "components/count/Counter"
import RtInput from "components/input/RtInput"
import RTPassword from "components/input/RTPassword"
import SectionContainer from "components/layout/SectionContainer"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import AuthLayout from "components/responsive-layouts/AuthLayout"
import RtTitle from "components/title/RtTitle"
import { useSignUp } from "hooks/api/useSignUp"
import { useSetValidationErrors } from "hooks/useSetFormErrors"
import { useEffect, useState } from "react"
import { Mail, Phone, User } from "react-feather"
import { useDispatch } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { clearAllValidationErrors } from "redux/features/formErrors"
import { getLockRemainingSeconds } from "utils/get-lock-remaining-time.util"
import { formatPhoneNumber, validatePhoneNumber } from "utils/phone-number-util"

const defaultFormData = {
  name: "",
  email: "",
  phone_number: "",
  password: "",
  password_confirmation: "",
}

function SignUp() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const setFormErrorsToStore = useSetValidationErrors()

  const isProceedToCheckout = location?.state?.isProceedToCheckout
  const slug = location?.state?.slug
  const lockId = location?.state?.lockId
  const expiresAt = location?.state?.expiresAt

  const remTimeInSeconds = getLockRemainingSeconds(expiresAt || "")

  const [formData, setFormData] = useState(defaultFormData)

  const handleInputChange = (value: { [key: string]: string }) => {
    setFormData((prev) => ({ ...prev, ...value }))
  }

  const redirectUrl = isProceedToCheckout
    ? `/checkout/${slug}/${lockId}/${expiresAt}`
    : "/"

  const signUpQueryClient = useSignUp(redirectUrl)
  const handleSubmitFormData = () => {
    signUpQueryClient.mutate(formData)
  }

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmitFormData()
    }
  }

  useEffect(() => {
    const isPhoneNumberValid = validatePhoneNumber({
      phoneNumber: formData.phone_number,
      countryCode: "KE",
    })

    if (isPhoneNumberValid) {
      const formattedPhoneNumber = formatPhoneNumber({
        phoneNumber: formData.phone_number,
        countryCode: "KE",
      })

      dispatch(clearAllValidationErrors())

      setFormData((prev) => ({ ...prev, phone_number: formattedPhoneNumber }))

      return
    } else {
      setFormErrorsToStore([
        {
          field: "phone_number",
          message: "Phone number is invalid",
        },
      ])
    }
  }, [formData?.phone_number])

  return (
    <div className="px-2">
      <SectionContainer>
        <AuthLayout>
          <div className="text-center">
            <RtTitle>Create Account</RtTitle>
            {isProceedToCheckout ? (
              <>
                <Counter timeInSeconds={remTimeInSeconds} />

                <p className="text-rt-gray mt-2">
                  You must create an account to purchase a ticket.
                </p>
              </>
            ) : (
              <p className="text-rt-gray">
                You must create an account to continue.
              </p>
            )}
          </div>

          <div className="mt-8 flex flex-col gap-2">
            <RtInput
              label="Full name"
              inputName="name"
              inputType="text"
              inputPlaceholder="Full name"
              onInputChange={handleInputChange}
              isRequired
              icon={
                <User className=" text-rt-blue" size={16} strokeWidth={1} />
              }
            />

            <RtInput
              label="Email"
              inputName="email"
              inputType="text"
              inputPlaceholder="Email"
              onInputChange={handleInputChange}
              isRequired
              icon={
                <Mail className=" text-rt-blue" size={16} strokeWidth={1} />
              }
            />

            <RtInput
              inputValue={formData?.phone_number || ""}
              label="Phone number"
              inputName="phone_number"
              inputType="text"
              inputPlaceholder="Phone number"
              onInputChange={handleInputChange}
              isRequired
              icon={
                <Phone className="text-rt-blue" size={16} strokeWidth={1} />
              }
            />

            <RTPassword
              label="Password"
              handleInputChange={handleInputChange}
            />

            <RTPassword
              label="Confirm password"
              inputName="password_confirmation"
              inputPlaceholder="Confirm password"
              handleEnterKey={handleEnterKey}
              handleInputChange={handleInputChange}
            />
          </div>

          <div className="mt-8">
            <RtButton
              variant="solid"
              overrideClass="w-full"
              onClick={handleSubmitFormData}
            >
              Create Account
            </RtButton>
            <RtButton
              variant="outline"
              overrideClass="w-full mt-4"
              onClick={() => navigate("/login")}
            >
              Back to Login
            </RtButton>
          </div>

          <div className="mt-8 text-center">
            <p className="text-rt-gray">
              By creating an account, you agree to our
            </p>
            <div>
              <Link
                to="/terms-of-service"
                target="_blank"
                className="text-rt-blue  border-b border-rt-blue mr-1"
              >
                Terms of Service
              </Link>
              <span className="text-rt-gray"> & </span>
              <Link
                to="/privacy-policy"
                target="_blank"
                className="text-rt-blue  border-b border-rt-blue mr-1"
              >
                Privacy Policy
              </Link>
              .
            </div>
          </div>
        </AuthLayout>
      </SectionContainer>

      <ActionLoadingModal
        isOpen={signUpQueryClient.isPending}
        loadingText="Please wait as we create your account..."
      />
    </div>
  )
}

export default SignUp
