import { createSlice } from "@reduxjs/toolkit"
import { KYC_NONE } from "constants/global.constants"
import { UserInterface } from "types/index.types"

const initialState: UserInterface = {
  id: null,
  name: "",
  email: "",
  phone_number: "",
  user_type: null,
  has_kyc: KYC_NONE,
  email_verified: false,
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handLogInUser: (state, action) => {
      console.log("handLogInUser", action.payload)
      state.id = action.payload.id
      state.name = action.payload.name
      state.email = action.payload.email
      state.phone_number = action.payload.phone_number
      state.user_type = action.payload.user_type
      state.has_kyc = action.payload.has_kyc
      state.email_verified = action.payload.email_verified
    },

    handLogOutUser: (state) => {
      state.id = null
      state.name = ""
      state.email = ""
      state.phone_number = ""
      state.user_type = null
      state.has_kyc = KYC_NONE
      state.email_verified = false
    },
  },
})

export const { handLogInUser, handLogOutUser } = userSlice.actions

export const userReducer = userSlice.reducer
