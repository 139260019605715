import { useEffect } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import RtButton from "components/button/RtButton"
import Spinner from "components/loader/Spinner"
import SuccessAnimation from "components/success-animation"
import useConfirmPayment from "hooks/api/useConfirmPayment"
import { SUPPORT_EMAIL } from "constants/global.constants"

function ConfirmPayment() {
  const navigate = useNavigate()
  const [IPaySearchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries(IPaySearchParams.entries())

  const confirmPaymentMutation = useConfirmPayment(searchParamsObject)

  useEffect(() => {
    confirmPaymentMutation.mutate()
  }, [])

  useEffect(() => {
    if (confirmPaymentMutation.isSuccess) {
      localStorage.setItem("tab", "Current")

      setTimeout(() => {
        navigate("/my-tickets?tab=Current")
      }, 7000)
    }
  }, [confirmPaymentMutation.isSuccess, navigate])

  if (confirmPaymentMutation.isPending) {
    return (
      <div className="m-5">
        <Spinner />
        <p className="text-center font-bold text-rt-violet mt-20">
          Please wait as we confirm your payment ...
        </p>
      </div>
    )
  }

  if (confirmPaymentMutation.isSuccess) {
    return (
      <div className="m-5">
        <SuccessAnimation />
        <div className="text-center font-bold text-rt-green">
          <p>
            Your payment has been confirmed, and your ticket has been sent to
            your email. Thank you for choosing
            <Link
              to={`/my-tickets?tab=Current`}
              className="underline cursor-pointer ml-2 text-rt-violet"
            >
              Ticket Vipi.
            </Link>
          </p>
        </div>
      </div>
    )
  }

  if (confirmPaymentMutation.isError) {
    return (
      <div className="m-5 text-center font-bold text-rt-red">
        <p>
          Oops! Something went wrong while confirming your payment. Please give
          it another shot. If the issue persists, reach out to our support team
          at {SUPPORT_EMAIL} Thanks for your understanding!
        </p>
        <div className=" mt-5 flex flex-row gap-10 justify-center w-full">
          <RtButton
            onClick={() => navigate("/")}
            overrideClass="bg-rt-white border border-rt-violet "
            textOverrideClass="text-rt-violet"
          >
            Home
          </RtButton>

          <RtButton onClick={() => confirmPaymentMutation.mutate()}>
            Retry
          </RtButton>
        </div>
      </div>
    )
  }

  return null
}


export default ConfirmPayment
