import { useInfiniteQuery } from "@tanstack/react-query"
import { handleGetRequest } from "utils/api-handler.util"
import removeFalsyValues from "utils/remove-falsy.util"

export function useListEvents(params: Record<string, any> = {}) {
  const cleanParams = removeFalsyValues(params)

  return useInfiniteQuery({
    queryKey: ["listEvents", cleanParams],
    queryFn: async ({ pageParam }) =>
      await handleGetRequest("events", { ...cleanParams, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.meta.current_page
      const lastPage_ = lastPage.data.meta.last_page

      const hasNextPage = currentPage < lastPage_

      return hasNextPage ? currentPage + 1 : undefined
    },
  })
}
