import RtButton from "components/button/RtButton"
import Counter from "components/count/Counter"
import RtInput from "components/input/RtInput"
import RTPassword from "components/input/RTPassword"
import SectionContainer from "components/layout/SectionContainer"
import ActionLoadingModal from "components/modal/ActionLoadingModal"
import AuthLayout from "components/responsive-layouts/AuthLayout"
import RtTitle from "components/title/RtTitle"
import { useLogin } from "hooks/api/useLogin"
import { useState } from "react"
import { User } from "react-feather"
import { useLocation, useNavigate } from "react-router-dom"
import { getLockRemainingSeconds } from "utils/get-lock-remaining-time.util"

const defaultFormData = {
  email: "",
  password: "",
}

function Login() {
  const location = useLocation()
  const navigate = useNavigate()

  const isProceedToCheckout = location?.state?.isProceedToCheckout
  const expiresAt = location?.state?.expiresAt || 0
  const slug = location?.state?.slug
  const lockId = location?.state?.lockId

  const remTimeInSeconds = getLockRemainingSeconds(expiresAt || "")

  const [formData, setFormData] = useState(defaultFormData)

  const handleInputChange = (value: { [key: string]: string }) => {
    setFormData((prev) => ({ ...prev, ...value }))
  }

  const redirectUrl = isProceedToCheckout
    ? `/checkout/${slug}/${lockId}/${expiresAt}`
    : "/"

  const loginQueryClient = useLogin(redirectUrl)
  const handleSubmitFormData = async () => {
    loginQueryClient.mutate(formData)
  }

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmitFormData()
    }
  }

  return (
    <div className="px-2">
      <SectionContainer>
        <div className="text-center">
          <RtTitle>Login</RtTitle>

          {isProceedToCheckout ? (
            <>
              <Counter timeInSeconds={remTimeInSeconds} />
              <h1 className="text-rt-red font-bold mt-4">
                You Are Not Logged In
              </h1>
              <p className="text-rt-gray">
                Please log in to your account to purchase the ticket(s).
              </p>
            </>
          ) : (
            <p className="text-rt-gray">
              Please log in to your account to continue.
            </p>
          )}
        </div>

        <AuthLayout>
          <div className="mt-8 flex flex-col gap-2">
            <RtInput
              label="Email"
              inputName="email"
              inputType="text"
              inputPlaceholder="Email"
              inputOverrideClass="bg-rt-white bg-opacity-100"
              onInputChange={handleInputChange}
              isRequired
              icon={
                <User className=" text-rt-blue" size={16} strokeWidth={1} />
              }
            />

            <RTPassword
              label="Password"
              handleEnterKey={handleEnterKey}
              handleInputChange={handleInputChange}
            />
            <div className="flex flex-row  items-center justify-end">
              Forgot password?
              <RtButton
                variant="link"
                overrideClass="forgot-password text-rt-blue ml-2"
                textOverrideClass="text-[14px]"
                onClick={() => navigate("/forgot-password")}
              >
                Reset here.
              </RtButton>
            </div>
          </div>

          <div className="mt-8">
            <RtButton
              onClick={() => handleSubmitFormData()}
              overrideClass="w-full"
            >
              Login
            </RtButton>

            <RtButton
              variant="outline"
              overrideClass="w-full mt-4"
              onClick={() =>
                navigate("/sign-up", {
                  state: {
                    isProceedToCheckout: isProceedToCheckout,
                    slug,
                    expiresAt,
                    lockId,
                  },
                })
              }
            >
              Create Account
            </RtButton>
          </div>
        </AuthLayout>

        <div className="text-center mt-8">
          <p className="text-rt-gray">
            By logging in to our system, you agree to our
          </p>
          <div>
            <RtButton
              variant="link"
              overrideClass="text-rt-blue"
              textOverrideClass="text-[14px]"
              onClick={() => navigate("/terms-of-service")}
            >
              Terms of Service
            </RtButton>
            <span className="text-rt-gray"> & </span>
            <RtButton
              variant="link"
              overrideClass="text-rt-blue"
              textOverrideClass="text-[14px]"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy.
            </RtButton>
          </div>
        </div>
      </SectionContainer>

      <ActionLoadingModal
        isOpen={loginQueryClient.isPending}
        loadingText="Please wait as you are being logged in..."
      />
    </div>
  )
}

export default Login
