import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import { SalesGraphProps } from "types/component-props.types"

function SalesGraph({
  categories,
  graphSeries,
  graphTitle,
  entity,
}: SalesGraphProps) {
  const series = [
    {
      name: entity,
      data: graphSeries || [],
    },
  ]

  const options: ApexOptions = {
    chart: {
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: graphTitle,
      align: "left",
      style: {
        color: "#0158b8",
        fontWeight: "600",
      },
    },
    grid: {
      row: {
        colors: ["#ffffff"],
      },
    },
    fill: {
      colors: ["#c702e4"],
    },
    yaxis: {
      title: {
        text: "Tickets",
      },
    },
    xaxis: {
      categories: categories,
    },
  }

  const chartWidth =
    categories.length > 10 ? `${categories.length * 10}%` : "100%"

  return (
    <div id="chart" className="bg-rt-white overflow-x-scroll">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
        width={chartWidth}
        stroke="smooth"
      />
    </div>
  )
}

export default SalesGraph
