import { useQuery } from "@tanstack/react-query"
import { CURRENT_TAB } from "constants/global.constants"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import { handleGetRequest } from "utils/api-handler.util"

export function useListMyTickets() {
  const { searchParams } = useRtSetUrlParams()

  const activeTab = searchParams.get("tab")

  const isActive =
    activeTab?.toLocaleLowerCase() === CURRENT_TAB.toLocaleLowerCase() ? 1 : 0

  const { isLoading, data, error } = useQuery({
    queryKey: ["listMyTickets", { is_active: isActive }],
    queryFn: async () =>
      await handleGetRequest("/tickets/mine", { is_active: isActive }),
  })

  return {
    isLoading,
    data,
    error,
  }
}
