import RtActionButton from "components/button/RtActionButton"
import ContentContainer from "components/layout/ContentContainer"
import SectionContainer from "components/layout/SectionContainer"
import RtTitle from "components/title/RtTitle"
import { Edit } from "react-feather"
import { useNavigate } from "react-router-dom"

const TicketCartDetails = ({ total, price, count, type }: any) => {
  return (
    <div className="bg-rt-light-blue bg-opacity-25 rounded-xl border-rt-blue border-[0.1rem] py-3 px-3">
      <div>{type} Ticket</div>
      <div className="flex justify-between mt-1">
        <span>{`${price} x ${count}`}</span>
        <div className="text-rt-blue font-black">{total}</div>
      </div>
    </div>
  )
}

function TicketInformation({ ticketInfo, slug }: any) {
  const navigate = useNavigate()

  const navigateToViewEvent = (slug: string) => {
    navigate(`/events/${slug}`)
  }

  const tickets = Object.keys(ticketInfo.tickets).map((ticketType) => ({
    ...ticketInfo.tickets[ticketType],
    type: ticketType,
  }))
  return (
    <SectionContainer>
      <div className="flex">
        <RtTitle overrideClass="mb-3 text-black flex-1">
          Ticket Information
        </RtTitle>
        <RtActionButton
          handleClick={() => navigateToViewEvent(slug)}
          icon={<Edit size={15} />}
          name="Edit"
        />
      </div>
      <ContentContainer overrideClass="flex flex-col gap-y-2">
        <>
          {tickets.map((ticket, index) => (
            <TicketCartDetails
              key={index}
              type={ticket.type}
              price={ticket.price}
              total={ticket.total}
              count={ticket.count}
            />
          ))}

          <div className="py-3 px-3 ">
            <div className="flex justify-end">
              <div className="flex w-max min-w-[70%]">
                <div className="flex-1">Total Tickets: </div>
                <span className="text-rt-blue font-black">
                  {ticketInfo.total_count}
                </span>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex w-max min-w-[70%]">
                <div className="flex-1">Total Price: </div>
                <span className="text-rt-blue font-black">
                  {ticketInfo.total_text}
                </span>
              </div>
            </div>
          </div>
        </>
      </ContentContainer>
    </SectionContainer>
  )
}

export default TicketInformation
