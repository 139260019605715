import { TicketQrCodeModalProps } from "types/component-props.types"
import BaseModal from "./BaseModal"
import useFetchTicketQrCode from "hooks/api/useFetchTicketQrCode"
import { showToaster } from "utils/show-toaster.util"
import Spinner from "components/loader/Spinner"

function TicketQrCodeModal({
  isOpen,
  onClose,
  ticketSlugCode,
}: TicketQrCodeModalProps) {
  //NOTE: Do not remove this,it prevent the useFetchTicketQrCode api...
  //...to be called since the ticket code and slug are only available when the modal is open
  if (!isOpen) return null

  const { isLoading, isError, data } = useFetchTicketQrCode(ticketSlugCode)

  if (isError) {
    showToaster(
      "error",
      "An error occurred while retrieving the ticket qr code",
      4000,
    )
    onClose()
    //TODO; Report to sentry -> Masho Ado 28/11/2023
    return
  }
  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      {isLoading ? (
        <Spinner />
      ) : (
        <img src={data} alt="qr code" width={"500px"} height="500px" />
      )}
    </BaseModal>
  )
}

export default TicketQrCodeModal
