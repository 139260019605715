import { ModalWrapperProps } from "types/component-props.types"
import BaseModal from "./BaseModal"
import { LogOut, User } from "react-feather"

import { useSelector } from "react-redux"
import { useLogout } from "hooks/api/useLogout"
import ActionLoadingModal from "./ActionLoadingModal"
import { isHost } from "utils/user.util"
import { useNavigate } from "react-router-dom"
import RtAvatar from "components/avatar"

function UserProfileModal({ isOpen, toggleModal }: ModalWrapperProps) {
  const user = useSelector((state: any) => state.user)
  const navigate = useNavigate()

  const onViewKyc = () => {
    toggleModal && toggleModal()
    navigate("/my-kyc")
  }

  const queryClient = useLogout(toggleModal)

  const handleLogout = async () => {
    queryClient.mutate()
  }

  return (
    <>
      <BaseModal isOpen={isOpen} onClose={toggleModal}>
        <div className="flex flex-col justify-center items-center mb-2 font-poppins">
          <RtAvatar name={user.name} />
          <div className="mt-4 flex flex-col gap-1 justify-center text-rt-gray text-center">
            <p>{user.name}</p>
            <span>{user.email}</span>
            <span>{user.phone_number}</span>
          </div>
        </div>

        <div className="mt-4 flex flex-row justify-between font-light text-sm border-t pt-2">
          <button
            className="flex items-center gap-2 p-2 text-rt-red bg-rt-red  bg-opacity-10 rounded-md"
            onClick={handleLogout}
          >
            <LogOut size={15} strokeWidth={2} />
            Log out
          </button>
          {/* {isHost() ? (
            <button
              className="flex items-center gap-2 p-2  text-rt-blue bg-rt-blue bg-opacity-5"
              onClick={navigateToHostProfile}
            >
              <User size={20} strokeWidth={1} />
              <span>View my profile</span>
            </button>
          ) : null} */}

          {isHost() ? (
            <button
              className="flex items-center gap-2 p-2  text-rt-blue bg-rt-blue bg-opacity-5 rounded-md"
              onClick={onViewKyc}
            >
              <User size={15} strokeWidth={2} />
              <span>My kyc</span>
            </button>
          ) : null}
        </div>
      </BaseModal>

      <ActionLoadingModal
        loadingText="Logging out..."
        isOpen={queryClient.isPending}
      />
    </>
  )
}

export default UserProfileModal
