import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Upload } from "react-feather"
import { RtFileUploaderProps } from "types/component-props.types"
import resizeImage from "utils/file-resizer"

function RtFileUploader({
  handleFiles,
  isMultiple = false,
  shouldResize = false,
  defaultFileUrl = "",
}: RtFileUploaderProps) {
  const [file, setFile] = useState<string>("")

  const onDrop = (acceptedFiles: File[]) => {
    if (shouldResize) {
      const resizeCallback = (resizedFileUri: Blob) => {
        handleFiles([resizedFileUri])
        setFile(URL.createObjectURL(resizedFileUri))
      }

      resizeImage({
        file: acceptedFiles[0],
        maxWidth: 300,
        maxHeight: 300,
        callback: resizeCallback,
      })
    } else {
      handleFiles(acceptedFiles)
      setFile(URL.createObjectURL(acceptedFiles[0]))
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: isMultiple,
  })

  useEffect(() => {
    // Revoke the data URIs to avoid memory leaks, will run on unmount or when file changes
    return () => {
      if (file) {
        URL.revokeObjectURL(file)
      }
    }
  }, [file])

  // set the default file if it exists
  useEffect(() => {
    if (defaultFileUrl) {
      setFile(defaultFileUrl)
    }
  }, [defaultFileUrl])

  return (
    <div
      {...getRootProps()}
      className="border-4 border-spacing-4 border-dashed border-rt-light-blue bg-rt-light-blue bg-opacity-25 px-2 py-4 w-full h-96 flex flex-col justify-center items-center"
    >
      {file && (
        <img src={file} alt="" className="mb-2 w-full h-full  object-contain" />
      )}
      <input {...getInputProps()} />
      <Upload className="text-rt-violet" size={20} strokeWidth={3} />

      <p className="text-rt-gray">
        {isDragActive
          ? "Drop the files here ..."
          : "Drag or click to upload files"}
      </p>
    </div>
  )
}

export default RtFileUploader
