import RtActionButton from "components/button/RtActionButton"
import RtCarousel from "components/carousel/RtCarousel"
import EventCard from "components/event-card/EventCard"
import NoItemsFallback from "components/fallback/NoItemsFallback"
import RtGrid from "components/grid/RtGrid"
import SectionContainer from "components/layout/SectionContainer"
import Spinner from "components/loader/Spinner"
import RtTitle from "components/title/RtTitle"
import {
  ACTIVE_EVENTS,
  LIST_STYLE_CAROUSEL,
  LIST_STYLE_GRID,
  PAST_EVENTS,
} from "constants/global.constants"
import useListMyEvents from "hooks/api/useListMyEvents"
import { useNavigate } from "react-router-dom"
import { EventInterFace } from "types/index.types"

interface HostEventsList {
  isActive: typeof ACTIVE_EVENTS | typeof PAST_EVENTS
  listStyle: typeof LIST_STYLE_GRID | typeof LIST_STYLE_CAROUSEL
}

function HostEventsList({ isActive, listStyle }: HostEventsList) {
  const navigate = useNavigate()

  const handleViewPastEvent = (slug: string) => {
    navigate(`/my-events/${slug}`)
  }

  const { data, isLoading, error } = useListMyEvents({ is_active: isActive })

  const pastEvents = data?.data?.data || []

  const hasPastEvents = pastEvents.length > 0

  if (isLoading)
    return (
      <SectionContainer>
        <Spinner />
      </SectionContainer>
    )

  if (error) return <p>An error occurred while fetching this data</p>

  return (
    <SectionContainer>
      <div className="flex">
        <RtTitle overrideClass="flex-1">
          {isActive === ACTIVE_EVENTS ? "Upcoming Events" : "Past Events"}
        </RtTitle>

        <RtActionButton
          handleClick={() => console.log("view all")}
          name={"View All"}
        />
      </div>

      {listStyle === LIST_STYLE_CAROUSEL ? (
        <RtCarousel>
          {hasPastEvents ? (
            pastEvents.map((event: EventInterFace, index: number) => (
              <EventCard
                key={`my-past-event-${index}`}
                event={event}
                isAdmin
                handleClickCard={handleViewPastEvent}
              />
            ))
          ) : (
            <NoItemsFallback title="You have no upcoming events at the moment..." />
          )}
        </RtCarousel>
      ) : (
        <RtGrid>
          {hasPastEvents ? (
            pastEvents.map((event: EventInterFace, index: number) => (
              <EventCard
                key={`my-past-event-${index}`}
                event={event}
                isAdmin
                handleClickCard={handleViewPastEvent}
              />
            ))
          ) : (
            <NoItemsFallback title="You have no past events at the moment..." />
          )}
        </RtGrid>
      )}
    </SectionContainer>
  )
}

export default HostEventsList
