import { Minus, Plus } from "react-feather"
import { useDispatch, useSelector } from "react-redux"
import {
  incrementTicketCount,
  decrementTicketCount,
  CartInterface,
} from "redux/features/cart"
import { PurchaseTicketProps } from "types/component-props.types"
import { cn } from "utils/cn.util"
import {
  formatDateToDisplay,
  getFormattedLocaleTime,
} from "utils/date-formatter.util"
// import { ON_GOING } from "constants/global.constants"
// import TicketStatusWrapper from "./TicketStatusWrapper"
import { showToaster } from "utils/show-toaster.util"

function PurchaseTicket({
  ticketGroup,
  slug,
  showTicketCartCount,
  disabled,
  overrideClass,
}: PurchaseTicketProps) {
  const cart = useSelector((state: any) => state.cart) as CartInterface
  const slugInCart = slug ? cart[slug] : null
  const ticketInCart = slugInCart ? slugInCart[ticketGroup.id] : null

  const ticketCartCount = ticketInCart?.count || 0
  const ticketTotalPrice = ticketGroup.price * ticketCartCount
  const seatsRemaining = ticketGroup.available_qty - ticketCartCount

  const dispatch = useDispatch()

  const handleTicketIncrement = (count: number) => {
    if (seatsRemaining === 0) {
      showToaster("error", "Oops! sorry ,no more seats remaining", 2000)
      return
    }

    dispatch(
      incrementTicketCount({
        slug: slug!,
        ticket_id: ticketGroup.id,
        price: Math.round(ticketGroup.price),
        count,
      }),
    )
  }

  const handleTicketDecrement = (count: number) => {
    dispatch(
      decrementTicketCount({
        slug: slug!,
        ticket_id: ticketGroup.id,
        count,
      }),
    )
  }

  return (
    <div
      className={cn(
        "flex flex-col gap-8 bg-rt-light-blue bg-opacity-25 border border-rt-blue p-4 rounded-xl relative",
        overrideClass,
      )}
    >
      {/* <TicketStatusWrapper
        status={ticketGroup.status}
        status_text={ticketGroup.status_text}
      /> */}
      <div>
        <TicketDetail
          title={ticketGroup.name}
          value={`${seatsRemaining} seats remaining`}
        />

        <TicketDetail
          title="Starts date"
          value={`${formatDateToDisplay(
            ticketGroup.start_date,
          )} -${getFormattedLocaleTime(ticketGroup.start_date)}`}
        />

        <TicketDetail
          title="End date"
          value={`${formatDateToDisplay(
            ticketGroup.end_date,
          )} -${getFormattedLocaleTime(ticketGroup.end_date)}`}
        />

        <div className="flex flex-row justify-between gap-4 mt-2">
          <div>
            <p>Ticket Price</p>
          </div>
          <i className="text-rt-blue font-bold ml-1">
            {ticketGroup.price_text}
          </i>
        </div>
      </div>
      {showTicketCartCount && !disabled && (
        <div className="flex justify-between items-center">
          <div>
            <p>Quantity</p>
            <div className="flex justify-between gap-8 mt-2 p-3 bg-rt-white rounded-xl border border-rt-light-blue">
              <button
                className="bg-rt-violet rounded-full p-1"
                onClick={() => handleTicketDecrement(1)}
                disabled={disabled}
              >
                <Minus className=" text-rt-white" size={20} strokeWidth={4} />
              </button>

              <p className="font-bold">{ticketCartCount}</p>

              <button
                className="bg-rt-violet rounded-full p-1"
                onClick={() => handleTicketIncrement(1)}
                disabled={disabled}
              >
                <Plus className=" text-rt-white" size={20} strokeWidth={4} />
              </button>
            </div>
          </div>
          <div>
            <p>Subtotal </p>
            <p className="font-bold text-rt-dark-blue">
              ksh {ticketTotalPrice || 0}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default PurchaseTicket

const TicketDetail = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className="flex justify-between gap-4">
      <p className="capitalize text-sm mb-2">{title} </p>
      <i className="text-rt-gray text-italic  text-sm font-light">{value}</i>
    </div>
  )
}
