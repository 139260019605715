import { EventInterFace } from "types/index.types"
import { getTimeAgoFromNow } from "utils/date-formatter.util"

interface AdminEventLinkProps {
  event: Partial<EventInterFace>
}
function AdminEventLink({ event }: AdminEventLinkProps) {
  return (
    <div className="flex justify-between py-2 border-b border-b-rt-light-gray cursor-pointer">
      <a
        className="cursor-pointer  text-rt-blue"
        href={`/my-events/${event.slug}`}
      >
        {event.name}
      </a>
      <i className="text-rt-gray">{getTimeAgoFromNow(event.start_date!)}</i>
    </div>
  )
}

export default AdminEventLink
