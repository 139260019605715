import RtImageDisplay from "components/image-display"
import RtTitle from "components/title/RtTitle"
import { Check, Globe, Hash, MapPin, Phone, User, XCircle } from "react-feather"
import { Link } from "react-router-dom"
import { formatDateToDisplay } from "utils/date-formatter.util"

interface HostKycProps {
  user: {
    id: number
    firstname: string
    lastname: string
    phone_number: string
    id_number: string
    company_name: string
    location_name: string
    gender: string
    nok_name: string
    nok_phone_number: string
    id_photo: string
    passport: string
    approved_by: number | null
    approved_at: string | null
    created_at: string
    updated_at: string
    rejected_at: string | null
    rejected_by: string | null
    reject_reason: string | null
  }
}

function HostKyc({ user }: HostKycProps) {
  return (
    <>
      <RtTitle>Host Details</RtTitle>
      <div className="flex flex-col gap-y-2 mt-2">
        <div className="flex flex-row items-center">
          <User className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <Link
            to="#"
            className="text-sm text-rt-blue border-b border-rt-blue mr-1"
          >
            {user?.firstname} {user?.lastname}
          </Link>
        </div>

        <div className="flex flex-row items-center">
          <Phone className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">
            {user?.phone_number}
          </p>
        </div>

        <div className="flex flex-row items-center">
          <Hash className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">
            ID : {user?.phone_number}
          </p>
        </div>

        <div className="flex flex-row items-center">
          <MapPin
            className=" text-rt-blue mr-1 pr-1"
            size={20}
            strokeWidth={1}
          />
          <p
            className="text-sm text-rt-gray font-light truncate"
            title={user?.location_name}
          >
            {user?.location_name}
          </p>
        </div>

        <div className="flex flex-row items-center">
          <Globe className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">
            Company : {user?.company_name}
          </p>
        </div>

        <div className="flex flex-row items-center">
          <User className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">{user?.gender}</p>
        </div>

        <div className="flex flex-row items-center">
          <User className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">
            Next of kin: {user?.nok_name}
          </p>
        </div>

        <div className="flex flex-row items-center">
          <Phone className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">
            Next of kin phone number: {user?.nok_phone_number}
          </p>
        </div>

        <div className="flex flex-row items-center">
          <Phone className="text-rt-blue mr-1 pr-1" size={20} strokeWidth={1} />
          <p className="text-sm text-rt-gray font-light">
            Next of kin phone number: {user?.nok_phone_number}
          </p>
        </div>

        {user?.approved_at && (
          <div className="flex flex-row items-center">
            <Check
              className="text-rt-green mr-1 pr-1"
              size={20}
              strokeWidth={1}
            />
            <p className="text-sm text-rt-green font-light">
              Approved at: {formatDateToDisplay(user?.approved_at)}
            </p>
          </div>
        )}

        {user?.rejected_at && (
          <div className="flex flex-row items-center">
            <XCircle
              className="text-rt-red mr-1 pr-1"
              size={20}
              strokeWidth={1}
            />
            <p className="text-sm text-rt-red font-light">
              Rejected at: {formatDateToDisplay(user?.rejected_at)}
            </p>
          </div>
        )}

        {user?.reject_reason && (
          <div className="flex flex-row items-center ">
            <XCircle
              className="text-rt-red mr-1 pr-1 "
              size={20}
              strokeWidth={1}
            />
            <p className="text-sm text-rt-red font-light">
              Rejected reason: {user?.reject_reason}
            </p>
          </div>
        )}

        <RtImageDisplay
          title="Host selfie:"
          imageUrl={user?.passport}
          overrideImageContainer="mt-4"
        />

        <RtImageDisplay
          title="Host ID photo:"
          imageUrl={user?.id_photo}
          overrideImageContainer="mt-4"
        />
      </div>
    </>
  )
}

export default HostKyc
