import {
  format,
  formatDistanceStrict,
  getUnixTime,
  isDate,
  parseISO,
} from "date-fns"

type TDate = string | Date

export function formatDateToDisplay(date: TDate, formatString: string = "PP") {
  const jsDate = new Date(date)

  const isValidDate = isDate(jsDate)

  if (!isValidDate) return "-"

  return format(new Date(date), formatString)
}

export function formatDateToIso(date: TDate) {
  const jsDate = isValidJsDate(date)

  if (!jsDate) return ""

  return new Date(date).toISOString()
}

export function isValidJsDate(date: TDate) {
  if (!date) return false

  const jsDate = new Date(date)

  return isDate(jsDate)
}

export function getFormattedLocaleTime(isoString: string) {
  const date = parseISO(isoString)
  return format(date, "ha")
}

export function getTimeAgoFromNow(laterDate: TDate) {
  return getTimeAgoBtnTwoDate(new Date(), laterDate)
}

export function getTimeAgoBtnTwoDate(earlierDate: TDate, laterDate: TDate) {
  const isEarlierDateValid = isValidJsDate(earlierDate)
  const isLaterDateValid = isValidJsDate(laterDate)

  if (!isEarlierDateValid || !isLaterDateValid) return

  return formatDistanceStrict(new Date(earlierDate), new Date(laterDate))
}

export function formatStartEndDateToIso(startDate: any, endDate: any) {
  const start_date = formatDateToIso(startDate)
  const end_date = formatDateToIso(endDate)

  return { start_date, end_date }
}

export function __getUnixTime(date: Date | string) {
  if (!isValidJsDate(date)) return 0

  const date_ = typeof date === "string" ? new Date(date) : date

  return getUnixTime(date_)
}

/**
 * Converts Unix time in seconds to a JavaScript Date object.
 * 
 * @param unixTime - Unix time in seconds.
 * @returns A Date object representing the specified Unix time.
 */
export function getDateFromUnix(unixTime: number): Date {
  const MILLISECONDS = 1000;
  return new Date(unixTime * MILLISECONDS);
}

