import EventCard from "components/event-card/EventCard"
import SectionContainer from "components/layout/SectionContainer"
import { EventInformationProps } from "types/component-props.types"

function EventInformation({ event }: EventInformationProps) {
  return (
    <SectionContainer
      sectionTitle="Event Information"
      overrideTitleClass="text-black"
    >
      <EventCard preview event={event} />
    </SectionContainer>
  )
}

export default EventInformation
