import ScrollToTopButton from "components/button/ScrollToTop"
import TopNav from "components/nav/TopNav"
import { RAPID_TECH_INSIGHTS_DOMAIN } from "constants/global.constants"
import { Link, Outlet } from "react-router-dom"

function UserLayout() {
  return (
    <div className="bg-rt-light-violet min-h-screen relative ">
      <TopNav />
      <main className="font-poppins">
        <ScrollToTopButton />
        <Outlet />
        <div className="absolute bottom-0 right-1/2 left-1/2 flex justify-center items-center my-0 mx-0">
          <p className="whitespace-nowrap">
            Made with <span className="text-rt-violet">❤️</span> by
            <Link
              to={RAPID_TECH_INSIGHTS_DOMAIN}
              target="_blank"
              style={{
                color: "#0158b8",
                marginLeft: "4px",
              }}
            >
              Rapid Tech Insights
            </Link>
          </p>
        </div>
      </main>
    </div>
  )
}

export default UserLayout
