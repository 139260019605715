import {
  TransformSalesBarGraphData,
  TransformSalesPieChartData,
} from "types/index.types"
import { formatDateToDisplay } from "./date-formatter.util"

export function transformSalesPieChartData(data: TransformSalesPieChartData[]) {
  const labels: string[] = []
  const pieSeries: number[] = []

  if (!data) return { labels, pieSeries }

  for (const i of data) {
    labels.push(i.name)

    const total_sales = parseInt(i.total_sales)
    pieSeries.push(total_sales)
  }

  return { labels, pieSeries }
}

export function transformSalesBarGraphData(data: TransformSalesBarGraphData[]) {
  const time: string[] = []
  const sales: number[] = []

  if (!data) return { time, sales }

  for (const i of data) {
    time.push(formatDateToDisplay(i.sold_at))
    sales.push(parseInt(i.total_sales))
  }

  return { time, sales }
}
