import EventCard from "components/event-card/EventCard"
import ErrorFallback from "components/fallback/ErrorFallback"
import RtSearch from "components/input/RtSearch"
import SectionContainer from "components/layout/SectionContainer"
import Spinner from "components/loader/Spinner"
import { useAdminEvents } from "hooks/api/useAdminEvents"
import useRtSetUrlParams from "hooks/useRtSetUrlParams"
import RtFilterByStatus from "pages/admin-dashboard/RtFilterByStatus"
import { useNavigate } from "react-router-dom"
import { EventInterFace } from "types/index.types"
import removeFalsyValues from "utils/remove-falsy.util"
import RtGrid from "components/grid/RtGrid"
import FiltersLayout from "components/responsive-layouts/FiltersLayout"
import { useRef } from "react"
import RtButton from "components/button/RtButton"
import useScrollIntoView from "hooks/useScrollIntoView"

function AdminEvents() {
  const { searchParams, handleSetUrlParams } = useRtSetUrlParams()

  const nextPageSpinnerRef = useRef(null)
  useScrollIntoView(nextPageSpinnerRef)

  const navigate = useNavigate()
  const status = searchParams.get("status")
  const q = searchParams.get("q")

  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useAdminEvents(removeFalsyValues({ status, q: q }))

  if (error) return <ErrorFallback />

  return (
    <div>
      <SectionContainer>
        <FiltersLayout>
          <RtSearch
            overrideClass="mb-4"
            handleSearch={(value) => handleSetUrlParams({ q: value })}
            searchValue={q || ""}
          />
          <RtFilterByStatus />
        </FiltersLayout>
      </SectionContainer>

      <SectionContainer>
        {error && <ErrorFallback />}
        {isLoading ? (
          <Spinner />
        ) : (
          <RtGrid>
            {data?.pages?.map((pages: any) => (
              <>
                {pages?.data?.data.map((event: EventInterFace) => (
                  <EventCard
                    event={event}
                    isAdmin
                    showEventImage={false}
                    handleClickCard={(slug) => navigate(`/my-events/${slug}`)}
                  />
                ))}
              </>
            ))}
          </RtGrid>
        )}
      </SectionContainer>

      <SectionContainer overrideClass=" flex justify-center">
        {isFetchingNextPage && (
          <div ref={nextPageSpinnerRef} className="h-48 m-auto">
            <Spinner />
          </div>
        )}

        {!isFetchingNextPage && hasNextPage && (
          <RtButton onClick={() => fetchNextPage()}>More events</RtButton>
        )}
      </SectionContainer>
    </div>
  )
}

export default AdminEvents
